import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import sanityClient from "../utils/SanityClient.jsx";
import { Container } from '../Theme/StyledComponents';
import Footer from '../Components/Footer.jsx';
import IntroText from '../Components/IntroText';

const Blog = () => {
    const [pageData, setPage] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "blog"]{
                title,
                slug,
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                    alt,
                },
                description,
                body,
            }`
        )
        .then((data) => setPage(data))
        .catch(console.error);
    }, [pageData]);
    return (
        <>
        <Fade delay={500}>
        <Container>
            <IntroText
            text="Write what should not be forgotten"
            />
            <div className="blog-items-container">
                {pageData && pageData.map((item, index) => {
                    return (
                        <Fade delay={1500} key={index}>
                        <Link 
                        className="blog-item"
                        key={item.slug.current}
                        to={"/Blog/" + item.slug.current}
                        >
                            <div className="blog-item-img">
                                <img src={item.mainImage.asset.url} alt="" />
                            </div>
                            <h2>
                                {item.title}
                            </h2>
                            <p className="blog-item-description">
                                {item.description}
                            </p>
                        </Link>
                        </Fade>
                    )
                })}
            </div>
            <Footer />
        </Container>
        </Fade>
        </>
    )
}

export default Blog
