import React from 'react'
import Fade from "react-reveal/Fade";

const IntroText = (props) => {
  return (
    <div className="intro-container">
         <Fade delay={750}>
            <h2>
                {props.text}
            </h2>
        </Fade>
    </div>
  )
}

export default IntroText