import React, { useState } from "react";

const ContactForm = () => {
    const [status, setStatus] = useState("submit");

    const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };

    let response = await fetch("/send", { 
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });

    setStatus("Submit");
    let result = await response.json();
    setStatus(result.status);
    };

    return (
        <form
        className="contactForm"
        onSubmit={handleSubmit}
        >
            <div className="contactForm-input">
                <label htmlFor="name">Name/Company</label>
                <input type="text" id="name" placeholder="Name/Company" required />
            </div>

          <div className="contactForm-input"> 
            <label htmlFor="email">Email Address</label>
            <input type="email" id="email" placeholder="Email Address" required />
          </div>

          <div className="contactForm-input">
            <label htmlFor="message">Your Message</label>
            <textarea id="message" placeholder="Message" required />
          </div>
          
          <button 
          className="contactForm-btn" 
          type="submit"
          >
            {status}
          </button>
        </form>
    )
};

export default ContactForm;