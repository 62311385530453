import React from 'react';
import Fade from 'react-reveal/Fade';
import * as FaIcons from 'react-icons/fa';

const Footer = () => {
    return (
        <footer>
            <Fade delay={1000}>
            <div className="footer__social">
                <a 
                href="/"
                alt=""
                rel="noreferrer"
                target="_blank">
                    <FaIcons.FaFacebookF />
                </a>
                <a 
                href="/"
                alt=""
                rel="noreferrer"
                target="_blank">
                    <FaIcons.FaInstagram />
                </a>
                <a 
                href="/"
                alt=""
                rel="noreferrer"
                target="_blank">
                    <FaIcons.FaTwitter />
                </a>
            </div>
            <div className="footer__credits">
                <p>
                2022© Deborah Serravalle
                </p>
                <p>
                    Privacy Policy
                </p>
                <a 
                href="https://adcanis.com"
                alt="Site Credits"
                rel="noreferrer"
                target="_blank">
                    Site Credits
                </a>
            </div>
            </Fade>
        </footer>
    )
}

export default Footer
