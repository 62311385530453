import React, {useState, useEffect} from 'react';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import BlockContent from '@sanity/block-content-to-react';
import sanityClient from "../utils/SanityClient.jsx";
import { Container } from '../Theme/StyledComponents';
import IntroText from '../Components/IntroText.jsx';
import Footer from "../Components/Footer.jsx";

const About = () => {
    const [pageData, setPage] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "about"]{
                intro,
                description,
                leftImage{
                    asset->{
                        _id,
                        url
                    },
                    alt,
                },
                middleImage{
                    asset->{
                        _id,
                        url
                    },
                    alt,
                },
                rightImage{
                    asset->{
                        _id,
                        url
                    },
                    alt,
                },
                about,
            }`
        )
        .then((data) => setPage(data))
        .catch(console.error);
    });

    return (
        <Fade delay={500}>
        <Container>
            {pageData && pageData.map((item) => {
                return (
                    <>
                    <IntroText
                    text={item.intro}
                    />
                    <Fade delay={1750}>
                    <div className="about-intro-description">
                        <p>{item.description}</p>
                    </div>
                    </Fade>
                    <div className="about-images-container">
                        <Fade deplay={2250}>
                        <img src={item.leftImage.asset.url} alt="" />
                        </Fade>
                        <img src={item.middleImage.asset.url} alt="" />
                        <Fade delay={2500}>
                        <img src={item.rightImage.asset.url} alt="" />
                        </Fade>
                    </div>
                    <div className="about-description">
                        <Slide bottom>
                        <BlockContent
                        blocks={item.about}
                        projectId="yu0d0be7"
                        dataset="production"
                        />
                        </Slide>                        
                    </div>
                    </>
                )
            })}
            <Footer />
        </Container>
        </Fade>
    )
}

export default About
