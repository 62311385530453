import styled, { keyframes } from 'styled-components';

export const FadeOut = keyframes
`
    75% {
        opacity: 100%;
    }
        opacity: 80%;
    }
    85% {
        opacity: 60%;
    }
    90% {
        opacity: 40%;
    }
    95% {
        opacity: 20%;
    }
    100% {
        opacity: 0;
    }
`;

export const Container = styled.div 
`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-bottom: 50px;
`;

export const Body = styled.div 
`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.body};
    height: 100%;
    width: 100%;
`;

export const Nav = styled.div 
`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
`;
