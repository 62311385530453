import React from 'react'

const MenuBtn = ({ onClick, text }) => {
    return (
        <>
        <div className="menuBtn" onClick={onClick}>
            <p className="menuBtn__txt">
            {text}
            </p>
        </div>
        </>
    )
}

export default MenuBtn
