export const  MenuItems = [
    {
        name: "Home",
        path: "/",
        cName: "navText",
        isActive: "is-active",
    },
    {
        name: "About",
        path: "/About",
        cName: "navText",
        isActive: "is-active",
    },
    {
      name: "Blog",
      path: "/Blog",
      cName: "navText",
      isActive: "is-active",
  },
    {
        name: "Shop",
        path: "/Shop",
        cName: "navText",
        isActive: "is-active",
    },
    {
        name: "Contact",
        path: "/Contact",
        cName: "navText",
        isActive: "is-active",
    },
];

export const itemVariants = {
  open: { 
      transition: "ease",
      opacity: 1,
  },
  closed: {
      transition: "ease",
      opacity: 0,
    },
  };
  
export const sideVariants = {
  open: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: 1
    }
  },  
  closed: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: -1
      }
    },
  };



