import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import sanityClient from "../utils/SanityClient.jsx";
import ContactForm from '../Components/ContactForm.jsx';
import IntroText from '../Components/IntroText.jsx';
import { Container } from '../Theme/StyledComponents';
import Footer from '../Components/Footer.jsx';

const Contact = () => {
    const [pageData, setPage] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "contact"]{
                intro,
                description,
            }`
        )
        .then((data) => setPage(data))
        .catch(console.error);
    }, [pageData]);
    
    return (
        <Fade delay={500}>
        <Container>
            {pageData && pageData.map((item) => {
                return (
                    <>
                     <IntroText
                    text={item.intro}
                    />
                    <div className="contact__bottom">
                        <div className="contact__bottom-left">
                            <Fade delay={1000}>
                            <p>
                            {item.description} 
                            </p>
                            </Fade>
                        </div>
                        <div className="contact__bottom-right">
                            <Fade delay={1250}>
                                <ContactForm />
                            </Fade>
                        </div>
                    </div>
                    </>
                )
            })}
            <Footer />
        </Container>
        </Fade>
    )
}

export default Contact
