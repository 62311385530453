import React from 'react';
import { NavLink } from 'react-router-dom';

const Logo = () => {
    return (
            <NavLink 
            className="logo"
            exact={true}
            to="/">
            <span>
                [
            </span>
            DS
            <span>
                ]
            </span>
            </NavLink>
    )
}

export default Logo
