import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Zoom';
import sanityClient from "../utils/SanityClient.jsx";
import BlockContent from '@sanity/block-content-to-react';
import { Container } from '../Theme/StyledComponents';
import Load from "../Pages/Preloader.jsx";
import Footer from '../Components/Footer.jsx';

const Post = () => {
    const [blogPost, setBlogPost] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        sanityClient
            .fetch(`*[slug.current == "${slug}"] {
                _id,
                title,
                slug,
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                    alt,
                },
                description,
                body,
            }`,
        )
        .then((data) => setBlogPost(data[0]))
        .catch(console.error);
    }, [slug]);
    if(!blogPost) return <Load />

    return (
        <>
        <Fade delay={500}>
        <Container>
            <Fade delay={1500}>
            <div 
            className="blog-post-main-img-container" 
            style={{
                backgroundImage: `
                linear-gradient(rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.75)),
                url(${blogPost?.mainImage.asset.url})
                `
            }}
            >
                <Fade delay={1750}>
                <div className="blog-post-title">
                    <h2> {blogPost?.title} </h2>
                </div>
                </Fade>
            </div>
            </Fade>
                <Slide bottom>
                    <BlockContent
                    className="blog-post-body"
                    blocks={blogPost?.body}
                    projectId="yu0d0be7"
                    dataset="production"
                    />
                </Slide>
            <Footer />
        </Container>
        </Fade>
        </>
    )
}
 
export default Post
