import React from 'react';
import { NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { AnimatePresence, motion, useCycle } from "framer-motion";
import { Nav } from '../Theme/StyledComponents';
import Logo from './logo.jsx';
import MenuBtn from './MenuBtn.jsx';
import { MenuItems, sideVariants, itemVariants } from "../utils/NavbarData.jsx";

const Navbar = () => {
    const [open, cycleOpen] = useCycle(false, true);
    
    return (
        <>
        <Nav>
        <Fade delay={1000}>
        <AnimatePresence>
        <div className="navbar">
          <Logo />
          <MenuBtn 
          text={open ? "Close" : "Menu"}
          onClick={cycleOpen} />
        </div>
  
        {open && (  
        <Fade delay={500}>
          <div className="navMenu" onClick={cycleOpen}>
          <motion.div 
          className="navbar__container"
          initial="closed"
          animate="open"
          exit="closed"
          variants={sideVariants}>
            
            <Fade right delay={500}>
            {MenuItems.map((item, index) => {
            return (
                <>
                <div key={index} className="navItem">
                  <NavLink 
                  exact={true}
                  to={item.path} 
                  className={item.cName}
                  activeClassName={item.isActive}
                  variants={itemVariants}>
                    {item.name}
                  </NavLink>
                </div>
                </>
              )
            })}
            </Fade>
            </motion.div>
            </div>
            </Fade>
            )}
        </AnimatePresence>
        </Fade>
        </Nav>
        </>
    )
}

export default Navbar
