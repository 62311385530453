import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AnimatedCursor from 'react-animated-cursor';
import { AnimatePresence } from "framer-motion";
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';
import Navbar from './Components/Navbar.jsx';
import './Theme/GlobalStyles.css';

// Pages
import Error from "./Pages/404.jsx";
import Home from './Pages/Home.jsx';
import About from './Pages/About.jsx';
import Blog from './Pages/Blog.jsx';
import BlogPost from "./Pages/BlogPost.jsx";
import Book from './Pages/Book.jsx';
import Store from './Pages/Store.jsx';
import Contact from './Pages/Contact.jsx';
import Load from './Pages/Preloader.jsx';


ReactGA.initialize("");

function App() {
  const [load, setLoad] = useState(true);
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });

  useEffect(() => {
    setTimeout(() => setLoad(false), 3750);
  }, []);

  return (
    <>
    {load === false ? (
    <div className="App">
      <BrowserRouter>
        <AnimatedCursor
        innerSize={10}
        outerSize={8}
        color='70, 70, 70'
        outerAlpha={0.2}
        innerScale={1}
        outerScale={5} />
        <CookieConsent
        location="bottom"
        buttonText="I Understand"
        cookieName="Cookie Policy"
        buttonStyle={{ color: "#191919", fontSize: "16px" }}
        expires={150}>
          The use of cookies is to help analyse the site traffic patterns. 
          Your personal data is never stored{" "}
        </CookieConsent>
        <Navbar />
        <AnimatePresence initial={false} exitBeforeEnter>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path="/About" element={<About/>} />
          <Route path="/Blog/:slug" element={<BlogPost />} />
          <Route path="/Blog" element={<Blog/>} />
          <Route path="/Shop/:slug" element={<Book />} />
          <Route path="/Shop" element={<Store/>} />
          <Route path="/Contact" element={<Contact/>} />
          <Route path="*" element={<Error/>} />
        </Routes>
        </AnimatePresence>
      </BrowserRouter>
    </div>
    ) : (
      <Load />
    )}
    </>
  );
}

export default App;
