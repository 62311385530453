import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import sanityClient from "../utils/SanityClient.jsx";
import { Container } from '../Theme/StyledComponents';
import Footer from "../Components/Footer.jsx";
import Load from './Preloader.jsx';

const Book = () => {
    const [book, setBook] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        sanityClient
            .fetch(`*[slug.current == "${slug}"] {
                _id,
                title,
                slug,
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                    alt,
                },
                synopsis,
            }`,
        )
        .then((data) => setBook(data[0]))
        .catch(console.error);
    }, [slug]);
    if(!book) return <Load />

    return (
        <Fade deplay={500}>
            <Container>
                <div className="book-body-container">
                <div className="book-left-container">
                    <Fade delay={1500}>
                    <h2>
                        {book?.title}
                    </h2>
                    </Fade>
                    <Fade delay={1750}>
                    <p>
                        {book?.synopsis}
                    </p>
                    <a href={book?.storeLink} targe="_blank" alt="">
                        Add to your collection
                    </a>
                    </Fade>
                </div>
                <div className="book-right-container">
                    <Fade delay={2000}>
                    <img src={book?.mainImage.asset.url} alt="" />
                    </Fade>
                </div>
                </div>
                <Footer />
            </Container>
        </Fade>
    )
}

export default Book