import React, { useState, useEffect } from 'react'
import Fade from 'react-reveal/Fade';
import Dots from '../Components/Dots';

const Load = () => {
    const [fadeOut, setFadeOut] = useState(true);

    useEffect(() => {
      setTimeout(() => setFadeOut(false), 3250)
    }, []);

    return (
        <div className="loader-container">
          <Fade 
          opposite 
          when={fadeOut}
          >
          <Dots />
          <Fade delay={1000}>
          <h2 className="loader-text">
            Loading....
          </h2>
          </Fade>
          
          </Fade>
        </div>
    )
}

export default Load
