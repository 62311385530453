import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import * as BsIcons from 'react-icons/bs';
import { Container } from '../Theme/StyledComponents';
import sanityClient from "../utils/SanityClient.jsx";
import Footer from "../Components/Footer.jsx";

const Home = () => {
    const [pageData, setPage] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "home"]{
                firstName,
                lastName,
                smallImage{
                    asset->{
                        _id,
                        url
                    },
                    alt,
                },
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                    alt,
                },
                largeImage{
                    asset->{
                        _id,
                        url
                    },
                    alt,
                },
                heading,
                slogan,
                "books": *[_type == "books"]{
                    title,
                    slug,
                    mainImage{
                        asset->{
                            _id,
                            url
                        },
                        alt,
                    },
                    synopsis,
                },
            }`
        )
        .then((data) => setPage(data))
        .catch(console.error);
    }, []);

    return (
        <Fade delay={500}>
        <Container>
            {pageData && pageData.map((item) => {
                const books = item.books;
                return (
                    <>
                        <div className="home-image-grid">
                            <Fade delay={1000}>
                                <div className="imageOne">
                                    <img src={item.smallImage.asset.url} alt=""/>
                                </div>
                            </Fade>
                            <Fade delay={1500}>
                                <div className="imageTwo">
                                    <img src={item.mainImage.asset.url} alt=""/>
                                </div>
                            </Fade>
                            <Fade delay={2000}>
                            <div className="introText">
                                <Fade delay={1750}>
                                    <h1>
                                        {item.firstName}
                                    </h1>
                                </Fade>
                                <Fade delay={2000}>
                                    <h1>
                                        {item.lastName}
                                    </h1>
                                </Fade>
                            </div>
                            </Fade>
                            <Slide bottom>
                                <div className="imageThree">
                                    <img src={item.largeImage.asset.url} alt=""/>
                                </div>
                            </Slide>
                        </div>
                        <Slide bottom>
                        <div className="home-description">
                            <h3>
                                {item.heading}
                            </h3>
                            <p>
                            {item.slogan}
                            </p>
                        </div>
                        </Slide>
                        <div className="featured-products">
                        {books && books.map((book, index) => {
                            return (
                                <Slide bottom>
                                <div className="featured-product-item" key={index}>
                                    <div 
                                    className="featured-product-item-img" 
                                    style={{
                                        backgroundImage: `url(${book.mainImage.asset.url})`
                                    }}
                                    />
                                    <h3>{book.title}</h3>
                                    <Link 
                                    className="featured-product-item-link"
                                    key={book.slug.current}
                                    to={"/Shop/" + book.slug.current}
                                    >   
                                    <p>
                                        <span>
                                        <BsIcons.BsArrowRightCircle />
                                        </span>
                                        View Book
                                    </p>
                                    </Link>
                                </div>
                                </Slide>
                            )
                        })}
                    </div>
                    </>
                )
            })}
            
            <Footer />
        </Container>
        </Fade>
    )
}

export default Home
